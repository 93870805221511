@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {

    body {
        height: 100vh;
        overflow: hidden;
        font-size: 14px;
    }

    /* a:hover {
        color: rgb(38 71 163 / 0.9);

    } */

    input,
    textarea,
    select {
        padding: 10px 12px;
        background: white;
        border-radius: 6px;
    }

    input:focus,
    textarea:focus,
    select:focus {
        outline: none;
        border-color: rgb(124, 4, 199, 0.4);

    }

    input::placeholder {
        font-size: 14px !important;
        color: rgba(0, 0, 0, 0.5);
    }

    textarea::placeholder {
        font-size: 14px !important;
        color: #878993;
    }

    /* ul {
        list-style-type: unset !important;
        list-style: unset;
    }

    ol {
        list-style-type: unset;
        list-style: unset;
    } */
}